import { createRoot } from "react-dom/client";

import { initializeApp } from "firebase/app";
import App from "pages/App";

import "styles/index.scss";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAv3s9wPglMmxPysDrwmhuKe8iNqRw4uCU",
  authDomain: "glauciagregoryadvogada.firebaseapp.com",
  projectId: "glauciagregoryadvogada",
  storageBucket: "glauciagregoryadvogada.appspot.com",
  messagingSenderId: "898637014048",
  appId: "1:898637014048:web:6ac7ef6a74902564158845",
  measurementId: "G-5WL61DC254",
};

initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
