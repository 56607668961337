import classNames from "classnames";

import "./styles.scss";

const SectionTitle = ({ children, color = "davysgrey", ...res }) => (
  <h3 {...res} className={classNames("section-title", color)}>
    {children}
  </h3>
);

export default SectionTitle;
