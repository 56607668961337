import { useId } from "react";
import "./styles.scss";
import { ReactComponent as Checkbox } from "images/checkbox.svg";
import { Fade } from "react-awesome-reveal";

const WE_DO = {
  col_1: [
    {
      label: "Direito de Família",
    },
    {
      label: "Divórcio em cartório",
    },
    {
      label: "Divórcio Consensual",
    },
    {
      label: "Divórcio Direto",
    },
    {
      label: "Divórcio Litigioso",
    },
    {
      label: "Divórcio com partilha de bens",
    },
    {
      label: "Reconhecimento e Dissolução de União Estável",
    },
    {
      label: "Ação de Alimentos (pensão alimentícia)",
    },
    {
      label: "Execução de Alimentos atrasados",
    },
    {
      label: "Revisão de Alimentos",
    },
    {
      label: "Exoneração de Alimentos",
    },
    {
      label: "Regulamentação de Convivência (visitação)",
    },
    {
      label: "Modificação de Cláusula de Convivência",
    },
    {
      label: "Ação de Guarda",
    },
  ],
  col_2: [
    {
      label: "Ação de Tutela",
    },
    {
      label: "Ação de Curatela (interdição)",
    },
    {
      label: "Elaboração de Pacto Antenupcial",
    },
    {
      label: "Investigação de Paternidade",
    },
    {
      label: "Elaboração de Contrato de Convivência",
    },
    {
      label: "Elaboração de Contrato de Namoro",
    },
    {
      label: "Atuação no Direito Sucessório Inventário Extrajudicial",
    },
    {
      label: "Inventário Judicial",
    },
    {
      label: "Arrolamento Comum",
    },
    {
      label: "Arrolamento Sumário",
    },
    {
      label: "Petição de Herança",
    },
    {
      label: "Ação de Deserdação",
    },
  ],
  col_3: [
    {
      label: "Pedido de Sobrepartilha",
    },
    {
      label: "Retificação de Partilha",
    },
    {
      label: "Ação Rescisória de Partilha",
    },
    {
      label: "Venda em Inventário",
    },
    {
      label: "Doação em Inventário",
    },
    {
      label: "Locação em Inventário",
    },
    {
      label: "Ação de Alvará",
    },
    {
      label: "Elaboração de Testamento e Codicilo",
    },
    {
      label: "Usufruto e Fideicomisso",
    },
    {
      label: "Declaração de Bens e Herdeiros na SEFAZ",
    },
    {
      label: "Planejamento Sucessório",
    },
    {
      label: "Planejamento Patrimonial",
    },
    {
      label: "Holding Familiar",
    },
    {
      label: "Outros Procedimentos",
    },
  ],
};

const WeDoCard = () => {
  const useID = useId();

  return (
    <Fade triggerOnce={true}>
      <div className="we-do-card">
        <div className="we-do-card__col">
          {WE_DO.col_1.map((col1) => (
            <div className="we-do-card__col__item" key={useID + col1.label}>
              <div className="we-do-card__col__item__icon">
                <Checkbox />
              </div>
              <span>{col1.label}</span>
            </div>
          ))}
        </div>
        <div className="we-do-card__col">
          {WE_DO.col_2.map((col2) => (
            <div className="we-do-card__col__item" key={useID + col2.label}>
              <div className="we-do-card__col__item__icon">
                <Checkbox />
              </div>
              <span>{col2.label}</span>
            </div>
          ))}
        </div>
        <div className="we-do-card__col">
          {WE_DO.col_3.map((col3) => (
            <div className="we-do-card__col__item" key={useID + col3.label}>
              <div className="we-do-card__col__item__icon">
                <Checkbox />
              </div>
              <span>{col3.label}</span>
            </div>
          ))}
        </div>
      </div>
    </Fade>
  );
};

export default WeDoCard;
