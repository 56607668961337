import Touchable from "components/Touchable";
import React from "react";
import { ReactComponent as Wpp } from "images/wpp.svg";

import "./styles.scss";

const ButtonDefault = ({ children, ...rest }) => {
  return (
    <Touchable {...rest}>
      <div className="btn-default">
        <Wpp />
        <span>Nos chame no WhatsApp</span>
      </div>
    </Touchable>
  );
};

export default ButtonDefault;
