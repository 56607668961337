import React, { useState } from "react";
import { Fade, Slide } from "react-awesome-reveal";

import { ReactComponent as Logo } from "images/logo.svg";
import { ReactComponent as Asps } from "images/asps.svg";
import { ReactComponent as LessIcon } from "images/less.svg";
import { ReactComponent as PlusIcon } from "images/plus.svg";
import { ReactComponent as LogoNoBordered } from "images/logo-no-bordered.svg";
import { ReactComponent as LocationIcon } from "images/location.svg";
import { ReactComponent as TelIcon } from "images/tel.svg";
import { ReactComponent as WppIcon } from "images/wppnumber.svg";
import { ReactComponent as EmailIcon } from "images/email.svg";
import { ReactComponent as SocialIcon } from "images/social.svg";

import Hands from "images/hands.png";
import Location from "images/map.png";

import "./styles.scss";
import ButtonDefault from "components/ButtonDefault";
import SectionTitle from "components/SectionTitle";
import WeDoCard from "components/WeDoCard";
import Touchable from "components/Touchable";
import classNames from "classnames";

const QUESTIONS = [
  {
    id: 1,
    show: false,
    title: "Posso cobrar pensão alimentícia atrasada?",
    content: (
      <>
        <p>
          Quando há uma ação judicial de alimentos em andamento (alimentos
          provisórios) ou já finalizada (alimentos definitivos). Se existir essa
          ação, o juiz pode, já no início, determinar um valor provisório a ser
          pago a título de alimentos mensalmente, o qual passará a valer a
          partir da citação daquele que tem o dever de pagá-la. Neste caso, a
          obrigação já existe e pode ser concretizada por uma sentença judicial
          ou por um acordo entre as partes, analisando o binômio necessidade e
          possibilidade: o poder aquisitivo daquele que pagará a pensão e as
          necessidades do respectivo filho que irá recebê-la.
        </p>
        <p>
          Também é possível cobrar quando houver um título executivo
          extrajudicial, ou seja, um contrato celebrado entre as partes
          identificando suas qualificações e o valor da pensão a ser paga
          mensalmente. Esse contrato representa um compromisso de pagamento da
          pensão e passa a ser válido com a assinatura de ambas as partes e com
          a presença de duas testemunhas. Ou seja, a pensão alimentícia atrasada
          só poderá ser cobrada se houver um prévio acordo entre as partes, que
          responsabilize uma delas por esse pagamento mensal.
        </p>
      </>
    ),
  },
  {
    id: 2,
    show: false,
    title: "Como o valor da pensão alimentícia é calculado?",
    content: (
      <>
        <p>
          O valor a ser pago mensalmente varia de situação para situação,
          levando em consideração as necessidades do filho e a situação
          financeira do genitor responsável por esse pagamento (binômio
          possibilidade-necessidade), devendo haver equilíbrio e
          proporcionalidade entre esses fatores. Desta forma, o valor exato a
          ser pago pela pensão alimentícia dependerá de uma avaliação do caso
          concreto, lembrando que deve ser observado o melhor interesse da
          criança/adolescente, a qual tem o direito de usufruir do mesmo padrão
          de vida do genitor que possui maior poder aquisitivo.
        </p>
      </>
    ),
  },
  {
    id: 3,
    show: false,
    title: "Como formalizar união estável?",
    content: (
      <>
        <p>
          A oficialização da união estável é importante pois serve como prova da
          existência dessa relação, o que facilita e garante os direitos de
          ambas as partes em caso de rompimento ou falecimento. Essa
          formalização pode ser feita por escritura pública lavrada no Cartório
          de Notas ou por um contrato particular firmado entre as partes, o qual
          também deverá ser registrado no Cartório de Registro de Títulos e
          Documentos. Importante ressaltar que independente do registro, este
          disciplinará uma série de regras: regime e titularidade de bens. Meu
          companheiro quer se separar e se recusa a dar a minha parte na casa
          que compramos durante a relação (que está somente no nome dele). Tenho
          ou não tenho direito sobre ela?
        </p>
        <p>
          Se não tiver escritura de união estável, a separação obedecerá ao
          regime de comunhão parcial de bens, que também é o mais optado pelos
          casais que firmaram a união pela devida escritura. Portanto, neste
          regime, para casos de separação, tudo aquilo que foi conquistado após
          a união, deverá ser repartido igualmente entre os dois,
          independentemente de quem tenha contribuído financeiramente.
        </p>
      </>
    ),
  },
];

const App = () => {
  const [questions, setQuestions] = useState(QUESTIONS);

  const handleWithOpenAndCloseQuestions = (questionId) => {
    setQuestions(
      questions.map((quest) => ({
        ...quest,
        show: questionId === quest.id ? !quest.show : quest.show,
      }))
    );
  };

  return (
    <div className="lp">
      <header className="lp__header">
        <div className="lp__header__content container max-w-full">
          <div className="lp__header__content__logo">
            <Fade left triggerOnce={true}>
              <Logo />
            </Fade>
          </div>
          <Fade left triggerOnce={true}>
            <div className="lp__header__content__txt">
              <h1>
                Escritório Especializado em Direito de Família e Sucessões
              </h1>
              <p>
                Ética e responsabilidade nas mais diversas demandas de natureza
                Familiar e Sucessórias, prezando sempre pelo ótimo atendimento
                de nossos clientes, visando a solução dos problemas de forma
                humanizada e estratégica.
              </p>
            </div>

            <ButtonDefault
              onClick={() =>
                window.open(
                  "https://api.whatsapp.com/send?phone=5555997149889&text=Ol%C3%A1,%20tudo%20bem?",
                  "_blank",
                  "noreferrer"
                )
              }
            />
          </Fade>
        </div>

        <div className="lp__header__logo-no-bordered">
          <Fade left triggerOnce={true}>
            <LogoNoBordered />
          </Fade>
        </div>
      </header>

      <div className="lp__about-us">
        <div className="lp__about-us__content container max-w-full">
          <Slide left triggerOnce={true}>
            <SectionTitle>Quem somos</SectionTitle>

            <div className="lp__about-us__content__row">
              <div className="lp__about-us__content__row__col">
                <p>
                  O escritório Glaucia Gregory advocacia, com sede em Santa
                  Rosa/RS, é especializado em Direito de família, com atuação há
                  muitos anos nessas demandas. Somos um escritório que prima
                  pela excelência na prestação de serviço, apto a gerenciar as
                  demandas no âmbito familiar objetivando o melhor resultado
                  para nossos clientes.
                </p>
                <p>
                  O Direito de família se debruça sobre a convivência familiar.
                  Por isso, atuamos de forma preventiva (planejamento sucessório
                  e patrimonial), extrajudicial (divórcio, partilha e inventário
                  em cartório) e também na chamada advocacia litigiosa, isto é,
                  nas ações judiciais onde não há consenso entre as partes
                  (ações de alimentos, guarda e "visitação"), por exemplo.
                </p>
              </div>
              <div className="lp__about-us__content__row__col">
                <p>
                  Nosso trabalho é focado em resolução de conflitos familiares e
                  sociais, buscando impactar positivamente nossa sociedade de
                  forma individual e coletiva. Acreditamos no poder
                  transformador de uma advocacia sensível, mediante escuta ativa
                  e acolhedora. Valorizamos a confiança e a segurança de nossos
                  clientes em nosso trabalho sendo nossa singularidade o modo
                  com que conseguimos demonstrar firmeza e maestria na execução
                  das estratégias traçadas a partir de olhares plurais.
                </p>
                <p>
                  O escritório se distingue pela relação próxima e de confiança
                  mútua que mantém com todos os clientes, prezando pela ética no
                  sigilo de cada caso.
                </p>
              </div>
            </div>
          </Slide>
        </div>
      </div>

      <div className="lp__what-we-do">
        <div className="lp__what-we-do__content container max-w-full">
          <SectionTitle color="chamoisee">
            <Slide left triggerOnce={true}>
              O que fazemos
            </Slide>
          </SectionTitle>

          <div className="lp__what-we-do__content__row">
            <WeDoCard />
          </div>
        </div>
      </div>

      <div className="lp__partners">
        <img
          src={Hands}
          alt="Conte Conosco | Glaucia Gregory Advocacia"
          loading="lazy"
        />
        <div className="lp__partners__right">
          <div className="lp__partners__right__text">
            <Slide direction="right" triggerOnce={true}>
              <span>conte conosco para resolver a sua situação</span>
              <ul>
                <li>• Atendimento online e imediato</li>
                <li>• Experiência comprovada</li>
                <li>• Excelente avaliação no Google</li>
                <li>• Atuação em vários estados do Brasil</li>
                <li>• Especialista na área</li>
              </ul>
            </Slide>
          </div>
        </div>
      </div>

      <div className="lp__customers-say">
        <div className="lp__customers-say__content container max-w-full">
          <SectionTitle color="chamoisee">
            <Slide triggerOnce={true}>O que nossos clientes dizem</Slide>
          </SectionTitle>

          <div className="lp__customers-say__content__row">
            <div className="lp__customers-say__content__row__text-card">
              <Slide triggerOnce={true}>
                <Asps />
                <span>“Dra. Glaucia é a advogada que me representa”</span>
                <p>
                  Em um dos momentos de maior desespero da minha vida, quando vi
                  que um direito fundamental ao qual eu tinha direito não estava
                  sendo respeitado, recorri a Dr. Glaucia, e ela realmente me
                  ajudou a ver uma luz no fim do túnel.
                </p>
                <p>
                  Dra. Glaucia foi além do que eu poderia pedir e possibilitou
                  que tudo se tornasse possível. Obrigada por me fazer sentir
                  como se meu caso estivesse no topo da sua lista de
                  prioridades, sempre me mantendo calma e tranquilizando os meus
                  medos.
                </p>
                <p>
                  Tenho orgulho em contar para o mundo que a Dr. Gláucia Gregory
                  é a advogada que me representa.
                </p>
              </Slide>
            </div>
            <div className="lp__customers-say__content__row__text-card">
              <Slide triggerOnce={true}>
                <Asps />
                <span>
                  “Ela tirou todo o medo e sanou todas as minhas dúvidas”
                </span>
                <p>
                  A Dra. Glaucia é aquela pessoa necessária na vida das pessoas,
                  a profissional mais humana que conheci. Procurei os serviços
                  dela quando estava desacreditada na justiça e quando
                  conversamos ela conseguiu abrir meu olhar para uma realidade
                  que eu não conseguia ver que era possível.
                </p>
                <p>
                  Ela conversou comigo como uma amiga e se colocando no meu
                  lugar foi me dando forças para encarar tudo que vinha pela
                  frente pelo meu filho, pelo futuro dele.
                </p>
                <p>
                  Ela tirou todo o medo e sanou todas as minhas dúvidas na hora
                  Com certeza afirmo que a Dra. Glaucia é a melhor!
                </p>
              </Slide>
            </div>
          </div>
        </div>
      </div>

      <div className="lp__contact-us">
        <div className="lp__contact-us__content container max-w-full">
          <span>
            <Fade triggerOnce={true}>Precisa de ajuda?</Fade>
          </span>
          <span>
            <Fade triggerOnce={true}> entre em contato conosco</Fade>
          </span>
          <Fade triggerOnce={true}>
            <ButtonDefault
              onClick={() =>
                window.open(
                  "https://api.whatsapp.com/send?phone=5555997149889&text=Ol%C3%A1,%20tudo%20bem?",
                  "_blank",
                  "noreferrer"
                )
              }
              className="mt-10"
            />
          </Fade>
        </div>
      </div>

      <div className="lp__questions">
        <div className="lp__questions__content container max-w-full">
          <SectionTitle color="chamoisee">
            <Slide triggerOnce={true}>Perguntas frequentes</Slide>
          </SectionTitle>

          <div className="mt-16">
            {questions &&
              questions.map((quest) => (
                <div
                  className="lp__questions__content__item"
                  key={quest.title + quest.id}
                >
                  <Touchable
                    onClick={() => handleWithOpenAndCloseQuestions(quest.id)}
                  >
                    <div className="lp__questions__content__item__title">
                      <span>
                        <Slide triggerOnce={true}>{quest.title}</Slide>
                      </span>

                      {quest.show ? <LessIcon /> : <PlusIcon />}
                    </div>

                    <div
                      className={classNames(
                        "lp__questions__content__item__content",
                        {
                          show: quest.show,
                        }
                      )}
                    >
                      {quest.content}
                    </div>
                  </Touchable>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="lp__footer">
        <div className="lp__footer__card">
          <h2>
            <Fade triggerOnce={true}>entre em contato</Fade>
          </h2>
          <address>
            <Fade triggerOnce={true}>
              <ul>
                <li>
                  <LocationIcon />
                  <span>Rua Almirante Cabral, 159 Centro | Santa Rosa RS</span>
                </li>
                <li>
                  <TelIcon />

                  <a href="tel:5535123808">55 3512 3808</a>
                </li>
                <li>
                  <WppIcon />
                  <a href="tel:55997149889">55 9 9714 9889</a>
                </li>
                <li>
                  <EmailIcon />
                  <a href="mailto:glaucia@glauciagregoryadvogada.com">
                    glaucia@glauciagregoryadvogada.com
                  </a>
                </li>
                <li>
                  <SocialIcon />
                  <span>@glaucia_gregory</span>
                </li>
              </ul>
            </Fade>
          </address>
        </div>

        <img
          className="lp__footer__map"
          src={Location}
          alt="Localização | Glaucia Gregory Advocacia"
          loading="lazy"
        />
      </div>

      <footer></footer>
    </div>
  );
};

export default App;
